const Base= "https://gestordeproyectos-api.azurewebsites.net";

export async function GetSitios() {
  try {
    const response = await fetch(Base + '/SitiosWeb/');
    if (!response.ok) {
      const error1 = await response.json();
      throw new Error(error1.mensaje || 'Error al obtener sitios web');
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error(error.message || 'Error de red');
  }
}
