
const Base= "https://gestordeproyectos-api.azurewebsites.net";

export async function  GetAllMatrizProyectos() {
    try {
      const response = await fetch(Base+'/matrizp/');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function  CreateMatrizProyectos(data) {
    try {
        const response = await fetch(Base+'/matrizp/', {
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
    }


    export async function  GetAllMatrizCambios() {
        try {
          const response = await fetch(Base+'/matrizc/');
          if (!response.ok) {
            throw new Error('Network response was not ok');
          }
          const jsonData = await response.json();
          return jsonData;
        } catch (error) {
          console.error('Error fetching data:', error);
          throw error;
        }
      }

      export async function  CreateMatrizCambios(data) {
        try {
            const response = await fetch(Base+'/matrizc/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
            });
            if (!response.ok) {
            throw new Error('Network response was not ok');
            }
            const jsonData = await response.json();
            return jsonData;
        } catch (error) {
            console.error('Error fetching data:', error);
            console.log(error);
            throw error;
        }
        }

        export async function deleteElement (id) {
          console.log(id);
            try {
              const response = await fetch(Base+'/matrizp/Delete/'+id, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const jsonData = await response.json();
              return jsonData;
            } catch (error) {
              console.error('Error fetching data:', error);
              throw error;
            }
          }


        export async function deleteElementCambios (id) {
          console.log(id);
            try {
              const response = await fetch(Base+'/matrizc/'+id, {
                method: 'DELETE',
                headers: {
                  'Content-Type': 'application/json',
                },
              });
              if (!response.ok) {
                throw new Error('Network response was not ok');
              }
              const jsonData = await response.json();
              return jsonData;
            } catch (error) {
              console.error('Error fetching data:', error);
              throw error;
            }
          }