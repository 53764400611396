
const Base= "https://gestordeproyectos-api.azurewebsites.net";



export async function  GetAllEncuestas(id) {
    try {
      const response = await fetch(Base+'/Encuesta/Proyecto/'+id);
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function  GetAllEncuestas2() {
    try {
      const response = await fetch(Base+'/Encuesta/Proyectos/');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  export async function  PostEncuesta(data) {
    console.log(data);
    try {
        const response = await fetch(Base+'/Encuesta/', {
                                          
        method: 'POST',
        headers: {
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(data)
        });
        if (!response.ok) {
        throw new Error('Network response was not ok');
        }
        const jsonData = await response.json();
        return jsonData;
    } catch (error) {
        console.error('Error fetching data:', error);
        throw error;
    }
    }

export async function  GetPremedioPreguntas() {
    try {
      const response = await fetch(Base+'/Encuesta/Preguntas/');
      if (!response.ok) {
        throw new Error('Network response was not ok');
      }
      const jsonData = await response.json();
      return jsonData;
    } catch (error) {
      console.error('Error fetching data:', error);
      throw error;
    }
  }

  