const Base= "https://gestordeproyectos-api.azurewebsites.net";

export async function GetHistorial() {
  try {
    const response = await fetch(Base + '/Historial/');
  
    if (!response.ok) {
      const error1 = await response.json();
      throw new Error(error1.mensaje || 'Error al obtener sitios web');
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error) {
    throw new Error(error.mensaje || 'Error de red');
  }
}

export async function GetHistorialRuta(id) {
  try {
    const response = await fetch(`${Base}/Historial/SitioWeb/${id}`); // Interpolamos el valor de `id` en la URL
    if (!response.ok) {
      const error = await response.json();
      throw new Error(error.mensaje || 'Error al obtener sitios web');
    }
    const jsonData = await response.json();
    return jsonData;
  } catch (error1) {
    throw new Error(error1.mensaje || 'Error de red');
  }
}